body {
    color: #e1e1e1;
    background-color: #232323;
}

h1 {
    margin-bottom: 30px;
}

.form-label, .form-check-label {
    font-weight: normal;
}

.form-control {
    color: #e1e1e1;
    background-color: #000000;
    border-color: #888888;
}

.form-control:focus {
    color: #e1e1e1;
    background-color: #232323;
    border-color: #888888;
}

.list-group-item {
    border-color: #888888;
    background-color: #000000;
}

.video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin: 0 0 80px 0;
}

.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-image {
    margin: 50px;
    width: 298px;
}

.call-to-action {
    margin-left: 50px;
    transform: translateY(50%) scale(2);
}

.hero {
    margin: 50px;
}

.video-container {
    margin: 50px;
}

.mentions {
    border-color: #1e1e1e !important;
    border-width: 10px !important;
}

.panel {
    background-color: #232323;
}

.options {
    margin-top: 30px;
}

.search-box {
    margin-top: 10px;
}

.hidden {
    visibility: hidden;
}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
    .video-container {
        margin: 15px 0;
    }
    .hero {
        margin-bottom: 150px;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
    .video-container {
        margin: 15px 0;
    }
    .hero {
        margin-bottom: 150px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .video-container {
        margin: 15px 0;
    }
    .hero {
        margin-bottom: 150px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}